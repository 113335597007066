import React, { Component } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toNumber, toString } from 'lodash';
import {
  Form,
  Button,
  Modal,
  Icon,
  Message,
  Select,
  Checkbox
} from 'semantic-ui-react';
import { utcMoment } from '../../utilities/dateUtils';
import Validator from 'validatorjs';
import { isEmpty, compact } from 'lodash';

import subscriptionProductTypeQuery from '../../graphql/subscriptionProductTypeQuery';
import client from '../../apollo';
import './subscriptionStyle.css';
import subscriptionCancellationReasons from '../../constants/subscriptionCancellationReasons';

class SubscriptionEdit extends Component {
  state = {
    id: '',
    dealerName: '',
    dealerNumber: '',
    productId: '',
    productName: '',
    subscriptionType: '',
    priceDs: null,
    pricePsiLpi: null,
    networkPlusRate: null,
    notifyEmail: '',
    startDate: '',
    priceStartDate: '',
    endDate: '',
    dsPaidPsi: false,
    productTypes: [],
    confirmUpdating: false,
    errors: {},
    cancellationReasons: [],
    subscriptionCancellationReason: ''
  };

  componentDidMount() {
    this.getProductTypesQuery();

    const { subscription } = this.props;

    const {
      id,
      subscription_id,
      dealerName,
      dealerNumber,
      productId,
      productName,
      subscriptionType,
      priceDs,
      pricePsiLpi,
      networkPlusRate,
      notifyEmail,
      startDate,
      endDate,
      dsPaidPsi,
      subscriptionCancellationReason
    } = subscription;

    this.setState({
      id: toNumber(id),
      subscription_id: subscription_id,
      dealerName: toString(dealerName),
      dealerNumber: parseInt(dealerNumber, 10),
      productId: toNumber(productId),
      productName: toString(productName),
      subscriptionType: toString(subscriptionType),
      priceDs: priceDs,
      pricePsiLpi: pricePsiLpi,
      networkPlusRate: networkPlusRate,
      notifyEmail: toString(notifyEmail),
      startDate: startDate,
      endDate: isEmpty(endDate) ? '' : utcMoment(moment(new Date(endDate))),
      priceStartDate: '',
      dsPaidPsi: dsPaidPsi,
      subscriptionCancellationReason: subscriptionCancellationReason
    });
  }

  onSelectHandler = field => (event, data) => {
    const { key } = data.options.find(o => o.value === data.value);
    if (field === 'productName') {
      this.setState({ [field]: data.value, productId: key });
    } else {
      this.setState({ [field]: data.value });
    }
  };

  onNumberChangeHandler = field => e => {
    const REGEX_NUMBER = /^[0-9\b]+$/i;

    if (REGEX_NUMBER.test(e.target.value) || isEmpty(e.target.value)) {
      this.setState({ [field]: e.target.value, errors: { [field]: '' } });
      return true;
    } else {
      this.setState({
        [field]: e.target.value,
        errors: { [field]: 'Invalid format number' }
      });
      return false;
    }
  };

  onEmailChangeHandler = field => event => {
    const REGEX_MULTIPLE_EMAILS = /^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})((,| ,|, | , |;| ;|; | ; |){1}[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})*$/i;

    if (
      REGEX_MULTIPLE_EMAILS.test(event.target.value) ||
      isEmpty(event.target.value)
    ) {
      this.setState({ [field]: event.target.value, errors: { [field]: '' } });
      return true;
    } else {
      this.setState({
        [field]: event.target.value,
        errors: {
          [field]:
            'Invalid email format. If using multi email e.g. user1@gmail.com,user2@gmail.com,...'
        }
      });
      return false;
    }
  };

  validateStartDateField = (field = 'startDate', value) => {
    const startDate = value;

    if (isEmpty(startDate) && field === 'startDate') {
      return 'This field is required';
    }
    return '';
  };

  validateEndDateField = (field = 'endDate', value, startDate) => {
    let endDate = value;
    let startD = moment(new Date(startDate)).format('YYYY-MM-DD');

    endDate = moment(new Date(endDate)).format('YYYY-MM-DD');

    if (
      moment(value).isValid() === false &&
      endDate === 'Invalid date' &&
      !isEmpty(value)
    ) {
      return 'Please input with a correct format value of YYYY-MM-DD';
    }

    if (isEmpty(endDate) && field === 'endDate') {
      return '';
    }

    if (
      !isEmpty(startD) &&
      !isEmpty(endDate) &&
      field === 'endDate' &&
      moment(new Date(endDate)) <= moment(new Date(startD))
    ) {
      return 'End Date cannot be less than or equal to Start Date';
    }

    return '';
  };

  dateFormat = () => {
    return 'YYYY-MM-DD';
  };

  ValidatePriceStartDate = subscription => {
    const {
      startDate,
      endDate,
      priceStartDate,
      priceDs,
      pricePsiLpi,
      networkPlusRate
    } = this.state;
    const startD = moment(new Date(startDate), this.dateFormat);
    const endD = moment(new Date(endDate), this.dateFormat);
    const priceStartD = moment(new Date(priceStartDate), this.dateFormat);

    if (!isEmpty(priceStartDate) && priceStartD.isValid() === false) {
      this.setState({
        errors: {
          priceStartDate:
            'Please input with a correct format value of YYYY-MM-DD'
        }
      });
      return true;
    }

    if (priceStartD.isBefore(startD)) {
      this.setState({
        errors: {
          priceStartDate: 'Selected Price Date is less than subscription'
        }
      });
      return true;
    }

    if (!isEmpty(endDate) && priceStartD.isAfter(endD)) {
      this.setState({
        errors: {
          priceStartDate:
            'Selected Price Date is greater than subscription end date'
        }
      });
      return true;
    }

    if (
      isEmpty(priceStartDate) &&
      (priceDs !== subscription.priceDs ||
        pricePsiLpi !== subscription.pricePsiLpi ||
        parseInt(networkPlusRate, 10) !== subscription.networkPlusRate)
    ) {
      this.setState({
        errors: {
          priceStartDate: 'Required'
        }
      });
      return true;
    }

    return false;
  };

  showMsgValidationDate = (field, value) => {
    let msg = '';
    let { startDate } = this.state;

    if (field === 'startDate') {
      msg = this.validateStartDateField(field, value);
    }

    if (field === 'endDate') {
      msg = this.validateEndDateField(field, value, startDate);
    }

    return msg;
  };

  onDateChangeHandler = field => date => {
    if (
      field === 'startDate' ||
      field === 'endDate' ||
      field === 'priceStartDate'
    ) {
      this.setState({
        [field]:
          !isEmpty(date) &&
          moment(date).isValid() &&
          moment(new Date(date._d)).format('YYYY-MM-DD'),
        errors: {
          [field]: ''
        }
      });
    }
    if (field === 'endDate' && isEmpty(date)) {
      this.setState({ subscriptionCancellationReason: '' });
    }
  };

  onDateFocusHandler = field => event => {
    const val = event.target.value;

    let { endDate } = this.state;

    if (
      field === 'startDate' &&
      moment(new Date(val)) >= moment(new Date(endDate))
    ) {
      this.setState({
        startDate: val,
        endDate: '',
        errors: {
          startDate: ''
        }
      });
      return;
    }

    const msg = this.showMsgValidationDate(field, val);

    this.setState({
      [field]: val,
      errors: {
        [field]: msg
      }
    });
  };

  getProductTypesQuery = () => {
    client
      .query({
        query: subscriptionProductTypeQuery
      })
      .then(({ data }) => {
        const { subscriptionProductType: productTypes } = data;
        this.setState({ productTypes: productTypes });
      })
      .catch(error => {
        console.log('Error: ', error);
      });
  };

  subscriptionIsActive = (startDate, endDate) => {
    if (moment(startDate).isSameOrBefore(moment(new Date(), this.dateFormat))) {
      if (
        isEmpty(endDate) ||
        (!isEmpty(endDate) &&
          moment(endDate).isSameOrAfter(moment(new Date(), this.dateFormat)))
      ) {
        return true;
      }
    }
    return false;
  };

  startDateAllowedRange = (field, subscription) => {
    const { startDate, endDate } = subscription;
    const isActive = this.subscriptionIsActive(startDate, endDate);

    let date = null;

    if (field === 'min') {
      date = isActive
        ? moment(startDate, 'YYYY-MM-DD').subtract(7, 'days')
        : null;
    } else if (field === 'max') {
      date = isActive ? moment(new Date(startDate), 'YYYY-MM-DD') : null;
    }
    return date;
  };

  validateStartDate = (selectedDate, subscription) => {
    let startDateAllowedRangeMin = this.startDateAllowedRange(
      'min',
      subscription
    );
    let startDateAllowedRangeMax = this.startDateAllowedRange(
      'max',
      subscription
    );

    if (moment(selectedDate).isBefore(startDateAllowedRangeMin)) {
      this.setState({
        errors: {
          startDate: 'Selected Date not allow for an active subscription'
        }
      });
      return true;
    } else if (
      startDateAllowedRangeMax.isValid() &&
      moment(selectedDate).isAfter(startDateAllowedRangeMax)
    ) {
      this.setState({
        errors: {
          startDate: 'Selected Date not allow for an active subscription'
        }
      });
    }
  };

  isANewSubscription = (selectedDate, subscription) => {
    if (
      this.subscriptionIsActive(subscription.startDate, subscription.endDate)
    ) {
      return true;
    }
    return false;
  };

  validationRules = {
    priceDs: 'required|numeric',
    productName: 'required|string',
    pricePsiLpi: 'numeric',
    notifyEmail: 'required',
    startDate: 'required'
  };

  isNonBundleProduct = prdName => {
    const productName = prdName.toLowerCase();
    return (
      productName === 'dealshield 21-day' ||
      productName === 'dealshield 30-day' ||
      productName === 'dealshield select 21-day' ||
      productName === 'dealshield select 30-day'
    );
  };

  render() {
    const bundlePricePsiLpi = prodName => {
      return this.isNonBundleProduct(prodName) ? '' : pricePsiLpi;
    };

    const submitForm = () => {
      let { active, dealerName, dealerNumber } = subscription;
      let {
        id,
        subscription_id,
        productId,
        productName,
        subscriptionType,
        priceDs,
        pricePsiLpi,
        networkPlusRate,
        notifyEmail,
        priceStartDate,
        startDate,
        endDate,
        dsPaidPsi,
        subscriptionCancellationReason
      } = this.state;

      const { subscriptions } = this.props;

      networkPlusRate = isEmpty(`${networkPlusRate}`)
        ? 0
        : parseInt(networkPlusRate, 10);
      startDate = moment(new Date(startDate)).isValid()
        ? moment(new Date(startDate)).format('YYYY-MM-DD')
        : 'Invalid Date';
      endDate = isEmpty(endDate)
        ? ''
        : moment(new Date(endDate)).isValid()
        ? moment(new Date(endDate)).format('YYYY-MM-DD')
        : 'Invalid Date';
      priceStartDate = isEmpty(priceStartDate)
        ? ''
        : moment(new Date(priceStartDate)).isValid()
        ? moment(new Date(priceStartDate)).format('YYYY-MM-DD')
        : 'Invalid Date';

      if (startDate === 'Invalid Date' && !isEmpty(startDate)) {
        this.setState({
          errors: {
            startDate: 'Please input with a correct format value of YYYY-MM-DD'
          }
        });
        return false;
      }

      if (endDate === 'Invalid Date' && !isEmpty(endDate)) {
        this.setState({
          errors: {
            endDate: 'Please input with a correct format value of YYYY-MM-DD'
          }
        });
        return false;
      }

      if (parseFloat(priceDs) !== 0 && parseFloat(priceDs) < 100) {
        this.setState({
          errors: {
            priceDs:
              'Please input with a correct Dealshield price (0 or from 100 to 999)'
          }
        });
        return false;
      }

      if (
        !isEmpty(`${pricePsiLpi}`) &&
        parseFloat(pricePsiLpi) !== 0 &&
        parseFloat(pricePsiLpi) < 100
      ) {
        this.setState({
          errors: {
            pricePsiLpi:
              'Please input with a correct PSI/LPI price (0 or from 100 to 999)'
          }
        });
        return false;
      }

      /*
      let recentInactiveRecord = subscriptions[0];
      let recentInactiveRecordEndDate = !isEmpty(recentInactiveRecord)
        ? moment(new Date(recentInactiveRecord.endDate)).format('YYYY-MM-DD')
        : '';
 
      if (
        !isEmpty(startDate) &&
        this.isANewSubscription(startDate, subscriptions) &&
        !isEmpty(recentInactiveRecordEndDate) &&
        moment(new Date(startDate)) <=
          moment(new Date(recentInactiveRecordEndDate))
      ) {
        this.setState({
          errors: {
            startDate: `Start Date should be greater than previous subscription end date(${
              recentInactiveRecordEndDate
            })`
          }
        });
        return false;
      }
      */

      const REGEX_MULTIPLE_EMAILS = /^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})((,| ,|, | , |;| ;|; | ; |){1}[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})*$/i;
      const REGEX_EMAIL_SEPARATES = /(?:,)|(?:;)|(\s)/g;
      const formatNotifyEmail = compact(
        notifyEmail.split(REGEX_EMAIL_SEPARATES)
      )
        .map(email => email.replaceAll(' ', ''))
        .filter(Boolean)
        .join(',');

      const validation = new Validator(this.state, this.validationRules, {
        'required.productName': 'This field is required',
        'required.priceDs': 'This field is required',
        'required.notifyEmail': 'This field is required',
        'required.startDate': 'This field is required'
      });

      if (
        !isEmpty(startDate) &&
        !isEmpty(endDate) &&
        moment(new Date(endDate)) <= moment(new Date(startDate))
      ) {
        this.setState({
          endDate: moment(new Date(endDate)).format('YYYY-MM-DD'),
          errors: {
            endDate: 'End Date cannot be less than or equal to Start Date'
          }
        });
        return false;
      }

      if (!isEmpty(endDate) && isEmpty(subscriptionCancellationReason)) {
        this.setState({
          errors: {
            subscriptionCancellationReason: 'Cancellation Reason is required'
          }
        });
        return false;
      }

      if (validation.fails()) {
        this.setState({ ...validation.errors });
        return false;
      }

      if (!REGEX_MULTIPLE_EMAILS.test(notifyEmail)) {
        this.setState({
          errors: {
            notifyEmail:
              'Invalid email format. If using multi email e.g. user1@gmail.com,user2@gmail.com,...'
          }
        });
        return false;
      }

      if (this.ValidatePriceStartDate(subscription)) {
        return false;
      }

      if (this.validateStartDate(startDate, subscriptions)) {
        return false;
      }

      pricePsiLpi = this.isNonBundleProduct(productName) ? 0 : pricePsiLpi;

      onSubmitHandler({
        id: parseInt(id, 10),
        subscription_id: parseInt(subscription_id, 10),
        dealerName: dealerName,
        dealerNumber: parseInt(dealerNumber, 10),
        productId: parseInt(productId, 10),
        productName: productName,
        subscriptionType: subscriptionType || 'BUYER',
        priceDs: parseFloat(priceDs),
        pricePsiLpi: parseFloat(pricePsiLpi),
        networkPlusRate: parseInt(networkPlusRate),
        notifyEmail: formatNotifyEmail || '',
        active: active,
        priceStartDate: priceStartDate || '',
        startDate: startDate,
        endDate: endDate,
        dsPaidPsi: dsPaidPsi,
        subscriptionCancellationReason: subscriptionCancellationReason
      });
    };

    const confirmUpdate = () => {
      this.setState({ confirmUpdating: true });
    };

    const {
      productId,
      productName,
      priceDs,
      pricePsiLpi,
      networkPlusRate,
      notifyEmail,
      errors,
      productTypes,
      priceStartDate,
      startDate,
      endDate,
      dsPaidPsi,
      confirmUpdating,
      subscriptionCancellationReason
    } = this.state;

    const {
      isModalOpen,
      onCloseHandler,
      onSubmitHandler,
      error,
      submitting,
      subscription
    } = this.props;

    const getDealerInfo = subscription => {
      if (isEmpty(subscription)) {
        return '';
      }

      if (isEmpty(subscription.dealerName)) {
        return `${subscription.dealerNumber}`;
      }

      return (
        !isEmpty(subscription) &&
        `${subscription.dealerNumber} - ${subscription.dealerName}`
      );
    };

    const onCheckboxHandler = field => (event, data) => {
      event.preventDefault();

      this.setState({ [field]: data.checked });
    };

    return (
      <Modal
        open={isModalOpen}
        closeIcon
        onClose={onCloseHandler}
        closeOnEscape={false}
        closeOnRootNodeClick={false}
      >
        <Modal.Header style={headerStyle}>
          <span>Edit DealShield Subscription</span>
          <span>{getDealerInfo(subscription)}</span>
        </Modal.Header>
        <Modal.Content image scrolling>
          <Modal.Description>
            {error && (
              <Message negative>
                <p>{error}</p>
              </Message>
            )}

            <Form>
              <Form.Group widths="equal">
                <Form.Field required error={!isEmpty(errors.productName)}>
                  <label>DealShield Product Selection</label>
                  <Select
                    placeholder="Select DealShield Product"
                    options={productTypes}
                    closeOnBlur
                    key={productId}
                    value={productName}
                    onChange={this.onSelectHandler('productName')}
                    style={{ width: '50%' }}
                  />
                  {errors.productName && (
                    <div style={{ color: 'red' }}>{errors.productName}</div>
                  )}
                </Form.Field>
              </Form.Group>
              <Form.Group>
                <Form.Field required error={!isEmpty(errors.priceDs)}>
                  <label>DealShield Price</label>
                  <input
                    name="priceDs"
                    value={priceDs}
                    defaultValue={priceDs}
                    onChange={this.onNumberChangeHandler('priceDs').bind(this)}
                    placeholder="N/A"
                    type="text"
                    pattern="\d*"
                    maxLength="3"
                  />
                  {errors.priceDs && (
                    <div style={{ color: 'red' }}>{errors.priceDs}</div>
                  )}
                </Form.Field>

                <Form.Field error={!isEmpty(errors.networkPlusRate)}>
                  <label>Network Plus Adjustment</label>
                  <input
                    name="networkPlusRate"
                    value={networkPlusRate}
                    defaultValue={networkPlusRate}
                    onChange={this.onNumberChangeHandler(
                      'networkPlusRate'
                    ).bind(this)}
                    placeholder="N/A"
                    type="text"
                    pattern="\d*"
                    maxLength="4"
                  />
                  {errors.networkPlusRate && (
                    <div style={{ color: 'red' }}>{errors.networkPlusRate}</div>
                  )}
                </Form.Field>

                <Form.Field error={!isEmpty(errors.pricePsiLpi)}>
                  <label>DealShield PSI / LPI Price</label>
                  <input
                    name="pricePsiLpi"
                    value={bundlePricePsiLpi(productName)}
                    onChange={this.onNumberChangeHandler('pricePsiLpi').bind(
                      this
                    )}
                    placeholder="N/A"
                    type="text"
                    pattern="\d*"
                    maxLength="3"
                    disabled={this.isNonBundleProduct(productName)}
                  />
                  {errors.pricePsiLpi && (
                    <div style={{ color: 'red' }}>{errors.pricePsiLpi}</div>
                  )}
                </Form.Field>

                <Form.Field error={!isEmpty(errors.priceStartDate)}>
                  <label>Price Start Date</label>
                  <DatePicker
                    key="priceStartDate"
                    style={{ padding: '0', width: '100%' }}
                    selected={
                      !isEmpty(priceStartDate) &&
                      moment(priceStartDate).isValid()
                        ? moment(priceStartDate)
                        : null
                    }
                    placeholderText={'Select price start date'}
                    minDate={moment(new Date(), 'YYYY-MM-DD')}
                    maxDate={moment(new Date(), 'YYYY-MM-DD').add('30', 'days')}
                    onChange={this.onDateChangeHandler('priceStartDate')}
                    onBlur={this.onDateFocusHandler('priceStartDate')}
                    dateFormat="YYYY/MM/DD"
                    maxLength="10"
                  />
                  {errors.priceStartDate && (
                    <div style={{ color: 'red' }}>{errors.priceStartDate}</div>
                  )}
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field
                  style={{ width: '50%' }}
                  required
                  error={!isEmpty(errors.notifyEmail)}
                >
                  <label>POC Email Address</label>
                  <input
                    name="notifyEmail"
                    placeholder="Please input email"
                    value={notifyEmail}
                    onChange={this.onEmailChangeHandler('notifyEmail').bind(
                      this
                    )}
                  />
                  {errors.notifyEmail && (
                    <div style={{ color: 'red' }}>{errors.notifyEmail}</div>
                  )}
                </Form.Field>
              </Form.Group>

              <Form.Group>
                <Form.Field required error={!isEmpty(errors.startDate)}>
                  <label>Subscription Start Date</label>
                  <DatePicker
                    key="startDate"
                    style={{ padding: '0', width: '100%' }}
                    selected={
                      !isEmpty(startDate) && moment(startDate).isValid()
                        ? moment(startDate)
                        : null
                    }
                    minDate={this.startDateAllowedRange('min', subscription)}
                    maxDate={this.startDateAllowedRange('max', subscription)}
                    onChange={this.onDateChangeHandler('startDate')}
                    onBlur={this.onDateFocusHandler('startDate')}
                    dateFormat="YYYY/MM/DD"
                    maxLength="10"
                  />
                  {errors.startDate && (
                    <div style={{ color: 'red' }}>{errors.startDate}</div>
                  )}
                </Form.Field>

                <Form.Field error={!isEmpty(errors.endDate)}>
                  <label>Subscription End Date</label>
                  <DatePicker
                    style={{ padding: '0', width: '100%' }}
                    selected={
                      !isEmpty(endDate) && moment(endDate).isValid()
                        ? moment(endDate)
                        : null
                    }
                    minDate={moment(new Date(startDate), 'YYYY-MM-DD ZZ')}
                    onChange={this.onDateChangeHandler('endDate')}
                    onBlur={this.onDateFocusHandler('endDate')}
                    dateFormat="YYYY/MM/DD"
                    maxLength="10"
                  />
                  {errors.endDate && (
                    <div style={{ color: 'red' }}>{errors.endDate}</div>
                  )}
                </Form.Field>
                <Form.Field>
                  <label>DS Paid PSI</label>
                  <Checkbox
                    checked={dsPaidPsi}
                    onChange={onCheckboxHandler('dsPaidPsi')}
                  />
                </Form.Field>
                <Form.Field
                  required
                  error={!isEmpty(errors.subscriptionCancellationReason)}
                  style={{ width: '33%' }}
                >
                  <label>Reason</label>
                  <Select
                    placeholder="Select cancellation reason"
                    options={
                      subscriptionCancellationReasons.CANCELLATION_REASONS
                    }
                    closeOnBlur
                    value={
                      isEmpty(endDate) ? '' : subscriptionCancellationReason
                    }
                    onChange={this.onSelectHandler(
                      'subscriptionCancellationReason'
                    )}
                    disabled={isEmpty(endDate)}
                  />
                  {errors.subscriptionCancellationReason && (
                    <div style={{ color: 'red' }}>
                      {errors.subscriptionCancellationReason}
                    </div>
                  )}
                </Form.Field>
              </Form.Group>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          {submitting && (
            <Button loading positive>
              Submitting...
            </Button>
          )}
          {!submitting && !confirmUpdating && (
            <Button color="green" onClick={confirmUpdate} inverted>
              <Icon name="checkmark" /> Update
            </Button>
          )}
          {confirmUpdating && !submitting && (
            <Button color="green" onClick={submitForm} inverted>
              <Icon name="checkmark" /> Are You Sure?
            </Button>
          )}
          <Button onClick={onCloseHandler}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

const headerStyle = {
  display: 'grid',
  justifyContent: 'space-between',
  gridTemplateColumns: 'auto auto'
};

export default SubscriptionEdit;
