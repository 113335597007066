import { ExportToCsv } from 'export-to-csv';

export const FileError = {
  marformedCSV: 'Malformed CSV file',
  missingHeader: 'Missing header(s) in uploaded file',
  invalidHeader: 'Header(s) do not match in the uploaded file.',
  invalidExtension: 'Please try again with a valid format file.',
  invalidFileSize: size => `File size exceeds the ${size} limit.`,
  emptyRecord: 'Missing record(s) in the uploaded file.',
  invalidContent: 'Invalid record(s) in the uploaded file.'
};

export const CVSChar = {
  emptyString: '',
  doubleQuote: '"',
  fieldSeparator: ',',
  newLine: '\n'
};

export const generateCSVFile = (data, filename) => {
  const optionsCSV = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: false,
    showTitle: false,
    useTextFile: false, // if true return .txt file instead of .csv
    useBom: true,
    useKeysAsHeaders: false,
    filename: filename
  };

  const csvExporter = new ExportToCsv(optionsCSV);

  csvExporter.generateCsv(data);
};

export const parseCSV = csvString => {
  const rows = [];
  let currentRow = [];
  let currentField = CVSChar.emptyString;
  let insideQuotes = false;

  for (let i = 0; i < csvString.length; i++) {
    const char = csvString[i];

    if (char === CVSChar.doubleQuote) {
      if (insideQuotes && csvString[i + 1] === CVSChar.doubleQuote) {
        // Handle escaped quote ("")
        currentField += CVSChar.doubleQuote;
        i++; // Skip next quote
      } else {
        insideQuotes = !insideQuotes;
      }
    } else if (char === CVSChar.fieldSeparator && !insideQuotes) {
      // End of field (only if not inside quotes)
      currentRow.push(currentField.trim());
      currentField = CVSChar.emptyString;
    } else if (char === CVSChar.newLine && !insideQuotes) {
      // End of row (only if not inside quotes)
      currentRow.push(currentField.trim());
      rows.push(currentRow);
      currentRow = [];
      currentField = CVSChar.emptyString;
    } else {
      currentField += char;
    }
  }

  // Push the last field and row if there's remaining content
  if (currentField.length > 0 || currentRow.length > 0) {
    currentRow.push(currentField.trim());
    rows.push(currentRow);
  }

  return rows;
};
