import React, { useState, useCallback } from 'react';
import {
  Modal,
  Button,
  Table,
  Label,
  Loader,
  Message,
  Icon
} from 'semantic-ui-react';
import CsvFileInput from '../../components/CsvFileInput';
import { compose } from 'recompose';
import { graphql } from 'react-apollo';
import PropTypes from 'prop-types';
import checkGroupDealerMutation from '../../graphql/checkGroupDealerMutation';
import importGroupDealerMutation from '../../graphql/importGroupDealerMutaion';
import { FileError } from '../../utilities/CsvUtil';

export const ImportStatus = {
  READY: 'Ready',
  WARNING: 'Warning',
  FAILED: 'Failed'
};
export const FileStatus = {
  INIT: -1,
  READY: 0,
  LOADING_FILE: 1,
  CHECKING_FILE: 2
};

export const ImportAction = {
  CHECK: 'check',
  SAVE: 'save'
};

const LIMIT_IMPORT_FILE_SIZE = 1;

function ImportDealer(props) {
  const {
    group,
    isModalOpen,
    onCloseHandler,
    checkGroupDealer,
    importGroupDealer
  } = props;
  const [status, setStatus] = useState(FileStatus.INIT);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [dealers, setDealers] = useState([]);
  const [isHideImport, setIsHideImport] = useState(false);
  const [loading, setLoading] = useState(false);
  const [invalidRecord, setInvalidRecord] = useState([]);

  const executeMutation = useCallback(
    async (dealers, action, mutation, returnMutationName) => {
      try {
        const inputs = {
          groupId: group.groupId,
          dealerNumber: dealers.map(dealer => Number(dealer.id))
        };
        const response = await mutation({
          variables: inputs
        });
        const { success, code, message, data: resData } = response.data[
          returnMutationName
        ];

        if (!success) {
          throw new Error(message);
        }

        resData.forEach(item => {
          const dealer = dealers.find(d => item.dealerNumber === Number(d.id));
          item.dealerName = dealer && dealer.name;
        });
        if (action === ImportAction.SAVE) {
          setData(prev =>
            prev.map(dealer => {
              dealer.isImported =
                resData.find(
                  d =>
                    d.dealerNumber === dealer.dealerNumber &&
                    d.message === dealer.message
                ).isImported || false;
              return dealer;
            })
          );
        } else {
          setData(resData);
        }
        setIsHideImport(action === ImportAction.SAVE);
        setLoading(false);
      } catch (e) {
        setErrorState(e.message);
      } finally {
        setStatus(FileStatus.READY);
      }
    },
    [group]
  );

  const onSelectFile = file => {
    setIsHideImport(false);
  };

  const onGetInvalidRecord = record => {
    setInvalidRecord(record);
  };

  const csvToDealer = csvRow => {
    if (!csvRow || csvRow.length === 0) return [];
    if (csvRow.some(row => row.length !== 2))
      throw new Error(FileError.marformedCSV);
    return csvRow.slice(1).map(row => ({
      id: row[0],
      name: row[1]
    }));
  };

  const isImportedMsg = value => {
    if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    }
    return '';
  };

  const setErrorState = (errorMsg = null) => {
    setError(errorMsg);
    setData([]);
    setStatus(FileStatus.INIT);
    setLoading(false);
  };

  const onCloseModal = useCallback(() => {
    setDealers([]);
    setIsHideImport(false);
    setErrorState();
    onCloseHandler();
  }, [onCloseHandler]);

  const onBeginReadFile = () => {
    setError(null);
    setStatus(FileStatus.LOADING_FILE);
  };

  const onEndReadFile = useCallback(
    (error, content) => {
      if (error) {
        setErrorState(error);
      } else {
        if (!content) {
          setErrorState();
        } else {
          try {
            setData([]);
            setLoading(true);
            setStatus(FileStatus.CHECKING_FILE);
            const dls = csvToDealer(content);
            setDealers(dls);
            executeMutation(
              dls,
              ImportAction.CHECK,
              checkGroupDealer,
              'checkGroupDealer'
            );
          } catch (e) {
            setErrorState(e.message);
          }
        }
      }
    },
    [executeMutation, checkGroupDealer]
  );

  const bulkImport = useCallback(() => {
    setLoading(true);
    try {
      if (status === FileStatus.READY) {
        executeMutation(
          dealers,
          ImportAction.SAVE,
          importGroupDealer,
          'importGroupDealer'
        );
      } else {
        setErrorState();
      }
    } catch (e) {
      setErrorState(e.message);
    }
  }, [executeMutation, importGroupDealer, status]);

  return (
    <Modal
      size={'large'}
      open={isModalOpen}
      onClose={onCloseHandler}
      closeOnEscape={true}
      closeOnRootNodeClick={false}
    >
      <Modal.Header style={{ borderBottom: 'none' }}>
        Bulk Import Dealer - [{group && group.groupName ? group.groupName : '-'}
        ]
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <CsvFileInput
            descriptionHolder="Click or drag file (.CSV) to upload (max 1MB)"
            onEndReadFile={onEndReadFile}
            onBeginReadFile={onBeginReadFile}
            onSelectFile={onSelectFile}
            onGetInvalidRecord={onGetInvalidRecord}
            loading={loading}
            maxSize={LIMIT_IMPORT_FILE_SIZE}
          >
            <span className="bg-red">
              To import please use our
              <a href={'/template/dealer_assoc.csv'}>&nbsp;CSV Template</a>
            </span>
          </CsvFileInput>
        </Modal.Description>
        <Modal.Description>
          {!error && <h4>Total record(s)</h4>}
          <div className="table-container">
            {error && (
              <Message negative>
                <span style={{ color: 'red' }}>{error || ''}</span>
                <div className="error-container">
                  {invalidRecord.map((record, irIdx) => (
                    <div key={irIdx}>{record || <>&nbsp;</>}</div>
                  ))}
                </div>
              </Message>
            )}
            {(status === FileStatus.CHECKING_FILE ||
              status === FileStatus.LOADING_FILE) && (
              <div className="d-center">
                <Loader inline="centered" size="big"></Loader>
              </div>
            )}
            {!!(data && data.length) && (
              <Table basic="very" singleLine>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Dealer ID</Table.HeaderCell>
                    <Table.HeaderCell>Dealer Name</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Message</Table.HeaderCell>
                    <Table.HeaderCell>Is Imported</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {data.map((importItem, rowIdx) => (
                    <Table.Row key={rowIdx}>
                      <Table.Cell>{importItem.dealerNumber}</Table.Cell>
                      <Table.Cell>{importItem.dealerName}</Table.Cell>
                      <Table.Cell>
                        <Label
                          circular
                          color={
                            importItem.status === ImportStatus.READY
                              ? 'green'
                              : importItem.status === ImportStatus.WARNING
                              ? 'orange'
                              : 'red'
                          }
                          empty
                          size={'mini'}
                          style={{ marginRight: 8 }}
                        />
                        {importItem.status}
                      </Table.Cell>
                      <Table.Cell>
                        {importItem.status !== ImportStatus.READY &&
                          importItem.message}
                      </Table.Cell>
                      <Table.Cell>
                        {isImportedMsg(importItem.isImported)}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            )}
          </div>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        {!isHideImport && (
          <Button
            color={'green'}
            onClick={bulkImport}
            loading={loading && status === FileStatus.READY}
            disabled={status !== FileStatus.READY}
          >
            Import
          </Button>
        )}
        <Button onClick={onCloseModal}>
          {isHideImport ? 'Close' : 'Cancel'}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

ImportDealer.propTypes = {
  group: PropTypes.object,
  isModalOpen: PropTypes.bool,
  onCloseHandler: PropTypes.func,
  checkGroupDealer: PropTypes.func,
  importGroupDealer: PropTypes.func
};

export default compose(
  graphql(checkGroupDealerMutation, { name: 'checkGroupDealer' }),
  graphql(importGroupDealerMutation, { name: 'importGroupDealer' })
)(ImportDealer);
