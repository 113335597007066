import React, { Component } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { toNumber, toString, toSafeInteger } from 'lodash';
import {
  Form,
  Button,
  Modal,
  Header,
  Icon,
  Select,
  Message
} from 'semantic-ui-react';
import { MILES_SELECTED_OPTIONS, DAYS_SELECTED_OPTIONS } from '../../constants';

import { utcMoment, dateTimeFormatted } from '../../utilities/dateUtils';
import { kindFormatted } from '../../utilities/productUtil';

class GuaranteePurchaseEdit extends Component {
  state = {
    id: '',
    dealerNumber: '',
    auctionAccessNumber: '',
    vehiclePurchasePrice: '',
    buyFee: '',
    year: '',
    make: '',
    model: '',
    odometerReading: '',
    partner: '',
    kind: '',
    milesSelected: '',
    daysSelected: '',
    transportReimbursement: '',
    emails: '',
    guaranteedAt: '',
    expiresAt: '',
    guaranteePrice: '',
    product: {}
  };

  componentDidMount() {
    const { guaranteePurchase } = this.props;
    const {
      id,
      kind,
      partner,
      guaranteedAt,
      expiresAt,
      guaranteeOffer: {
        emails,
        daysSelected,
        milesSelected,
        transportReimbursement,
        guaranteePrice,
        product,
        vehicleInformation: { make, model, odometerReading, year },
        auctionInformation: {
          buyFee,
          dealerNumber,
          auctionAccessNumber,
          vehiclePurchasePrice
        }
      }
    } = guaranteePurchase;

    const getProductName = product => {
      return product
        ? product.name
        : `${kindFormatted(kind)} (${daysSelected} D ${milesSelected} M)`;
    };

    this.setState({
      id: toNumber(id),
      dealerNumber: toNumber(dealerNumber),
      auctionAccessNumber: toNumber(auctionAccessNumber),
      buyFee: toNumber(buyFee),
      vehiclePurchasePrice: toNumber(vehiclePurchasePrice),
      year: toNumber(year),
      make: toString(make),
      model: toString(model),
      odometerReading: toNumber(odometerReading),
      partner: toString(partner),
      kind: toString(kind),
      milesSelected: toNumber(milesSelected),
      daysSelected: toNumber(daysSelected),
      transportReimbursement: toSafeInteger(transportReimbursement),
      emails: toString(emails),
      guaranteedAt: guaranteedAt,
      expiresAt: dateTimeFormatted(utcMoment(expiresAt)),
      guaranteePrice: toNumber(guaranteePrice),
      productName: toString(getProductName(product))
    });
  }

  render() {
    const {
      guaranteePurchase,
      isModalOpen,
      onCloseHandler,
      onSubmitHandler,
      error,
      submitting
    } = this.props;

    const onChangeHandler = field => event => {
      let { value, min, max } = event.target;
      if (field === 'guaranteePrice') {
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));
      }
      this.setState({ [field]: value });
    };
    const onSelectHandler = field => (event, data) => {
      this.setState({ [field]: data.value });
    };
    const onDateChangeHandler = field => date => {
      this.setState({ [field]: moment(date, 'YYYY-MM-DD HH:mm:ss') });
    };

    const submitForm = () => {
      const {
        id,
        dealerNumber,
        auctionAccessNumber,
        buyFee,
        vehiclePurchasePrice,
        year,
        make,
        model,
        odometerReading,
        partner,
        kind,
        milesSelected,
        daysSelected,
        transportReimbursement,
        emails,
        guaranteedAt,
        guaranteePrice
      } = this.state;

      onSubmitHandler({
        id: parseInt(id, 10),
        dealerNumber: parseInt(dealerNumber, 10),
        auctionAccessNumber: parseInt(auctionAccessNumber, 10),
        buyFee: parseFloat(buyFee || 0),
        vehiclePurchasePrice: parseFloat(vehiclePurchasePrice),
        year: parseInt(year, 10),
        make,
        model: model || '',
        odometerReading: parseInt(odometerReading, 10),
        partner: partner || '',
        kind,
        milesSelected: parseInt(milesSelected, 10),
        daysSelected: parseInt(daysSelected, 10),
        transportReimbursement: parseFloat(transportReimbursement),
        emails,
        guaranteedAt,
        guaranteePrice: parseFloat(guaranteePrice || 0)
      });
    };

    const {
      guaranteeOffer: {
        vehicleInformation: { vin }
      }
    } = guaranteePurchase;

    return (
      <Modal
        open={isModalOpen}
        closeIcon
        onClose={onCloseHandler}
        closeOnEscape={false}
        closeOnRootNodeClick={false}
      >
        <Modal.Header>Edit Guarantee Purchase for VIN {vin}</Modal.Header>
        <Modal.Content image scrolling>
          <Modal.Description>
            {error && (
              <Message negative>
                <Message.Header>
                  Error while updating guarantee purchase
                </Message.Header>
                <p>{error}</p>
              </Message>
            )}
            <Form>
              <Header size="small" color="blue">
                Vehicle Details
              </Header>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Year</label>
                  <input
                    name="year"
                    disabled
                    value={this.state.year}
                    onChange={onChangeHandler('year').bind(this)}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Make</label>
                  <input
                    name="make"
                    disabled
                    value={this.state.make}
                    onChange={onChangeHandler('make').bind(this)}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Model</label>
                  <input
                    name="model"
                    disabled
                    value={this.state.model}
                    onChange={onChangeHandler('model').bind(this)}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Odometer</label>
                  <input
                    name="odometerReading"
                    disabled
                    value={this.state.odometerReading}
                    onChange={onChangeHandler('odometerReading').bind(this)}
                  />
                </Form.Field>
              </Form.Group>
              <Header size="small" color="blue">
                Auction Details
              </Header>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Dealer Number</label>
                  <input
                    name="dealerNumber"
                    disabled
                    value={this.state.dealerNumber}
                    onChange={onChangeHandler('dealerNumber').bind(this)}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Auction Access Number</label>
                  <input
                    name="auctionAccessNumber"
                    disabled
                    value={this.state.auctionAccessNumber}
                    onChange={onChangeHandler('auctionAccessNumber').bind(this)}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Buy Fee</label>
                  <input
                    name="buyFee"
                    disabled
                    value={this.state.buyFee}
                    onChange={onChangeHandler('buyFee').bind(this)}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Vehicle Purchase Price</label>
                  <input
                    name="vehiclePurchasePrice"
                    disabled
                    value={this.state.vehiclePurchasePrice}
                    onChange={onChangeHandler('vehiclePurchasePrice').bind(
                      this
                    )}
                  />
                </Form.Field>
              </Form.Group>
              <Header size="small" color="blue">
                Guarantee Details
              </Header>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Guaranteed At</label>
                  <DatePicker
                    style={{ padding: '0', width: '100%' }}
                    selected={utcMoment(this.state.guaranteedAt)}
                    onChange={onDateChangeHandler('guaranteedAt').bind(this)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="YYYY-MM-DD HH:mm:ss ZZ"
                    disabled
                  />
                </Form.Field>
                <Form.Field>
                  <label>Expiration Date</label>
                  <input
                    name="expiresAt"
                    disabled
                    value={this.state.expiresAt}
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Partner Network</label>
                  <input
                    name="partner"
                    disabled
                    value={this.state.partner}
                    onChange={onChangeHandler('partner').bind(this)}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Product</label>
                  <input value={this.state.productName} disabled />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Miles Selected</label>
                  <Select
                    placeholder="Select Miles"
                    name="milesSelected"
                    options={MILES_SELECTED_OPTIONS}
                    onChange={onSelectHandler('milesSelected')}
                    value={this.state.milesSelected}
                    disabled
                  />
                </Form.Field>
                <Form.Field>
                  <label>Days Selected</label>
                  <Select
                    placeholder="Select Days"
                    name="daysSelected"
                    options={DAYS_SELECTED_OPTIONS}
                    onChange={onSelectHandler('daysSelected')}
                    value={this.state.daysSelected}
                    disabled
                  />
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Guarantee Price</label>
                  <input
                    name="guaranteePrice"
                    value={this.state.guaranteePrice}
                    onChange={onChangeHandler('guaranteePrice').bind(this)}
                    type="number"
                    min="0"
                    max="9999"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Transport Reimbursement</label>
                  <input
                    name="transportReimbursement"
                    disabled
                    value={this.state.transportReimbursement}
                    onChange={onChangeHandler('transportReimbursement').bind(
                      this
                    )}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Emails</label>
                  <input
                    name="emails"
                    value={this.state.emails}
                    onChange={onChangeHandler('emails').bind(this)}
                  />
                </Form.Field>
              </Form.Group>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          {submitting && (
            <Button loading positive>
              Submitting...
            </Button>
          )}
          {!submitting && (
            <Button color="green" onClick={submitForm} inverted>
              <Icon name="checkmark" /> Update
            </Button>
          )}
          <Button onClick={onCloseHandler}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default GuaranteePurchaseEdit;
