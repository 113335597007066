import React, { Component } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import withPagination from './WithPagination';
import LoadingDataEnhancer from './LoadingData';
import doc from '../assets/doc.png';

import ReactDropzone from 'react-dropzone';
import axios from 'axios';
import localStore from 'store';
import { isEmpty, find } from 'lodash';
import {
  Message,
  Image,
  Button,
  Label,
  Loader,
  Dimmer,
  Dropdown,
  Segment,
  Container,
  Modal,
  Header,
  Icon,
  Grid,
  Checkbox
} from 'semantic-ui-react';

import { UPLOAD_OPTIONS, SOP_OPTIONS } from '../constants/fileUploader';

import FileUploaderUploaderList from '../views/FileUploader/List';
import fileUploaderQuery from '../graphql/fileUploaderQuery.js';
import { setPage } from '../data/page/actions';

class FileUploader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFile: null,
      flashMessage: null,
      allFiles: [],
      files: [],
      loading: false,
      selectedOptions: null,
      selectedSource: null,
      selectedOptionsDesc:
        'Select An Upload / Process Function Below. Allowed File Types (.CSV)',
      confirmOpen: false,
      selectSourceType: false,
      optionsKey: null,
      processMessage: true
    };
  }

  setConfirmOpen = e => {
    this.setState({ confirmOpen: e });
  };

  onDrop = files => {
    this.setState({ selectedFile: { name: files[0].name, file: files[0] } });
  };

  onSelectOptions = (e, { value }) => {
    if (!isEmpty(value)) {
      var data = this.getSelectedItem(UPLOAD_OPTIONS, value);
      if (data.value === 'SopEventsUploader') {
        this.setState({ selectSourceType: true, processMessage: true });
      } else if (this.isValidUploadOption(data.value)) {
        this.setState({
          selectSourceType: false,
          selectedSource: null
        });
      }
      this.setState({
        selectedOptions: data.value,
        selectedOptionsDesc: data.desc
      });
    }
  };

  isValidUploadOption = selectedOption => {
    const uploaderOptions = UPLOAD_OPTIONS.map(option => option.value);
    return uploaderOptions.includes(selectedOption);
  };

  onSelectSource = (e, { value }) => {
    if (!isEmpty(value)) {
      var data = this.getSelectedItem(SOP_OPTIONS, value);
      this.setState({
        selectedSource: data.value
      });
    }
  };

  onSelectProcessMessages = checked => {
    this.setState({
      processMessage: checked
    });
  };

  getSelectedItem = (items, value) => {
    return find(items, ['value', value]);
  };

  saveFile = () => {
    const {
      selectedFile,
      selectedOptions,
      selectSourceType,
      selectedSource
    } = this.state;
    if (!isEmpty(selectedFile)) {
      if (!isEmpty(selectedOptions)) {
        if (
          this.isValidUploadOption(selectedOptions) ||
          (selectSourceType && !isEmpty(selectedSource))
        )
          this.setConfirmOpen(true);
        else {
          this.setState({ loading: false, flashMessage: 'Select A Source' });
        }
      } else {
        this.setState({ loading: false, flashMessage: 'Select An Option' });
      }
    } else {
      this.setState({ loading: false, flashMessage: 'Upload A File' });
    }
  };

  uploadImage = () => {
    const {
      selectedFile,
      selectedOptions,
      selectedSource,
      processMessage
    } = this.state;

    if (!isEmpty(selectedOptions) && !isEmpty(selectedFile)) {
      this.setState({ loading: true, flashMessage: '' });
      const formData = new FormData();
      const token = localStore.get('jwt');

      formData.append('file', selectedFile.file);
      formData.append('model', 'UploadedFile');
      formData.append('modelType', selectedOptions);
      if (selectedSource !== null) {
        formData.append('sourceType', selectedSource);
      }
      formData.append('AUTHORIZATION', token ? `${token}` : null);
      formData.append('processMessage', processMessage);

      axios
        .post(process.env.REACT_APP_UPLOAD_API_URL, formData)
        .then(response => {
          const {
            data: { success }
          } = response;
          if (success) {
            this.resetForm();
          } else {
            this.setState({
              loading: false,
              flashMessage: 'Failed To Upload File'
            });
          }
        })
        .catch(e => {
          this.setState({ loading: false });
        });
    } else {
      this.setState({ loading: false });
    }
  };

  uniqueId = key => {
    return `${key}_${new Date().getTime()}`;
  };

  resetForm = () => {
    this.setState({
      flashMessage: 'Successfully Uploaded file and queued for processing',
      selectedFile: null,
      selectedOptions: null,
      selectedSource: null,
      selectedOptionsDesc: null,
      optionsKey: this.uniqueId(),
      selectSourceType: null,
      processMessage: false,
      loading: false
    });
    this.props.data.refetch();
    this.props.setPage({});
  };

  processFile = () => {
    this.setConfirmOpen(false);
    this.uploadImage();
  };

  enableUploadButton = () => {
    const { selectedFile, selectedOptions } = this.state;
    return selectedFile === null || selectedOptions === null;
  };

  render() {
    const {
      data: { fileUploader }
    } = this.props;

    const allFiles = fileUploader;

    const {
      flashMessage,
      selectedFile,
      loading,
      selectedOptionsDesc,
      confirmOpen,
      selectedOptions,
      selectSourceType,
      selectedSource,
      optionsKey,
      processMessage
    } = this.state;

    const fileName = !isEmpty(selectedFile) ? selectedFile.name : '';

    const thumb = {
      display: 'inline-flex',
      borderRadius: 2,
      border: '1px solid #eaeaea',
      marginBottom: 2,
      marginRight: 2,
      width: 100,
      height: 100,
      padding: 4,
      boxSizing: 'border-box'
    };

    const thumbInner = {
      display: 'flex',
      minWidth: 0,
      overflow: 'hidden'
    };

    const img = {
      display: 'block',
      width: 'auto',
      height: '100%'
    };

    return (
      <div className="drop-zone">
        {flashMessage && (
          <Message
            onDismiss={this.handleDismiss}
            content={flashMessage}
            positive
          />
        )}
        <Modal open={confirmOpen} size="small" dimmer="blurring">
          <Header icon>
            <Icon name="info circle" />
            Confirm File Upload / Process
            <br />
            <Message>
              <p>
                <b>Note - {selectedOptionsDesc}</b>
              </p>
            </Message>
          </Header>
          <Modal.Content>
            <p>Uploaded File - {fileName}</p>
            <p>
              Selected Process - <b> {selectedOptions} </b>
            </p>
            {selectedSource && (
              <p>
                Source - <b> {selectedSource} </b>
              </p>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="red"
              inverted
              onClick={() => this.setConfirmOpen(false)}
            >
              <Icon name="remove" /> No
            </Button>
            <Button color="green" inverted onClick={this.processFile}>
              <Icon name="checkmark" /> Yes
            </Button>
          </Modal.Actions>
        </Modal>

        <Segment>
          <br />
          <br />
          <Container>
            <div class="ui inverted divider"></div>
            <div className="ui one column grid">
              <div className="ui row">
                <Message
                  icon="upload"
                  header="File Processor & Uploader"
                  content="Utilize this page to upload and process CSV files on APMS"
                />
              </div>
              <div className="ui row">
                <Grid container columns={2}>
                  <Grid.Row>
                    <Grid.Column width={5}>
                      <p>
                        <b>Select File To Upload:</b>
                      </p>
                    </Grid.Column>

                    <Grid.Column>
                      <div>
                        <ReactDropzone
                          accept=".csv"
                          onDrop={this.onDrop}
                          multiple={false}
                          className="react-drop-zone"
                        >
                          Click Here To Upload File (CSV File)
                        </ReactDropzone>
                      </div>
                      <div>
                        <div className="ui row">
                          <Grid.Row>
                            <br />
                            {selectedFile && (
                              <Label>
                                <div style={thumb}>
                                  <div style={thumbInner}>
                                    <Image src={doc} style={img} />
                                  </div>
                                </div>
                                <br />
                                {selectedFile.name}
                              </Label>
                            )}
                          </Grid.Row>
                        </div>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            </div>
          </Container>
          <Container style={{ marginTop: '50px' }}>
            <Message>
              Select An Upload / Process Function Below. Allowed File Types
              (.CSV)
            </Message>
            <br />

            <Grid container columns={2}>
              <br />
              <Grid.Row>
                <Grid.Column width={5}>
                  <p>
                    <b>Select Upload Process for CSV* file:</b>
                  </p>
                </Grid.Column>
                <Grid.Column>
                  <Dropdown
                    key={optionsKey}
                    fluid
                    basic
                    placeholder="Select Upload Type"
                    selection
                    value={this.selectedOptions}
                    options={UPLOAD_OPTIONS}
                    onChange={this.onSelectOptions}
                  />
                  <br />
                  <p>
                    {!isEmpty(selectedOptions) && (
                      <div>
                        <Icon name="attention" />
                        {selectedOptionsDesc}
                      </div>
                    )}
                  </p>
                  <p>
                    {!isEmpty(selectedOptions) &&
                      selectedOptions === 'BadSellerFileUploader' && (
                        <div>
                          <span style={{ fontWeight: 'bold', color: 'red' }}>
                            NOTICE:
                          </span>
                          <br />
                          <span style={{ color: 'red' }}>
                            If you upload the bad seller file during business
                            hours, the file will only be processed at 11 PM EST
                          </span>
                        </div>
                      )}
                  </p>
                </Grid.Column>
              </Grid.Row>

              {selectSourceType && (
                <Grid container columns={2}>
                  <Grid.Row>
                    <Grid.Column width={5}>
                      <p>
                        <b>Select File Source:</b>
                      </p>
                    </Grid.Column>
                    <Grid.Column>
                      <Dropdown
                        fluid
                        placeholder="Select Source"
                        selection
                        options={SOP_OPTIONS}
                        onChange={this.onSelectSource}
                      />
                    </Grid.Column>
                  </Grid.Row>

                  <br />
                  <Grid.Row>
                    <Grid.Column width={5}>
                      <p>
                        <b>Process Messages:</b>
                      </p>
                    </Grid.Column>
                    <Grid.Column width={5}>
                      <Checkbox
                        fluid
                        selection
                        defaultChecked
                        label=" Use this option to process SOP request"
                        value={processMessage}
                        onChange={(e, data) =>
                          this.onSelectProcessMessages(data.checked)
                        }
                      />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}
            </Grid>
            <br />
            <Button
              color="green"
              onClick={this.saveFile}
              disabled={this.enableUploadButton()}
              inverted
            >
              Process File
            </Button>
            {loading && (
              <Dimmer active inverted>
                <Loader size="large">Loading</Loader>
              </Dimmer>
            )}
          </Container>
          <br />
          <br />

          <Container>
            <div class="ui inverted divider"></div>
            <FileUploaderUploaderList allFiles={allFiles} />
          </Container>
        </Segment>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setPage: fields => {
    dispatch(setPage());
  }
});

export default compose(
  withRouter,
  withPagination(fileUploaderQuery, 'fileUploader'),
  LoadingDataEnhancer,
  connect(null, mapDispatchToProps)
)(FileUploader);
