import React, { useCallback, useState, useEffect } from 'react';
import ReactDropzone from 'react-dropzone';
import { Container, Image } from 'semantic-ui-react';
import {
  getFileExtension,
  isValidFileSize,
  readFileAsText
} from '../utilities/fileUtil';
import { CVSChar, FileError, parseCSV } from '../utilities/CsvUtil';
import csvIcon from '../assets/csv.png';
import PropTypes from 'prop-types';

function CsvFileInput(props) {
  const {
    readFile,
    onSelectFile,
    onBeginReadFile,
    onEndReadFile,
    onGetInvalidRecord,
    children,
    descriptionHolder,
    loading,
    maxSize
  } = props;

  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const checkMalformedFile = useCallback(
    (file, content) => {
      const header = content.shift();
      const missingHeader = !header || header.every(h => !h);
      const invalidHeader =
        header && (header[0] !== 'dealer_num' || header[1] !== 'dealer_name');
      const invalidContent = content.some(
        dealer => !dealer[0] || isNaN(dealer[0])
      );
      const invalidFileSize = !isValidFileSize(file, maxSize);
      const invalidExtension = getFileExtension(file.name) !== 'csv';
      const emptyRecord = !content || content.length === 0;

      const errorMsg = buildFileErrorMsg({
        missingHeader,
        invalidHeader,
        invalidContent,
        invalidFileSize,
        invalidExtension,
        emptyRecord,
        content
      });

      return { isValidFile: !errorMsg, errorMsg };
    },
    [maxSize]
  );

  const buildFileErrorMsg = ({
    missingHeader,
    invalidHeader,
    invalidContent,
    invalidFileSize,
    invalidExtension,
    emptyRecord,
    content
  }) => {
    if (invalidExtension) {
      return FileError.invalidExtension;
    }
    if (missingHeader) {
      return FileError.missingHeader;
    }
    if (invalidFileSize) {
      return FileError.invalidFileSize('1MB');
    }
    if (invalidHeader) {
      return FileError.invalidHeader;
    }
    if (emptyRecord) {
      return FileError.emptyRecord;
    }
    if (invalidContent) {
      if (onGetInvalidRecord && content.length) {
        const invalidRecord = content
          .filter(dealer => !dealer[0] || isNaN(dealer[0]))
          .map(d => {
            return d.join(', ');
          });
        onGetInvalidRecord(invalidRecord);
      }
      return FileError.invalidContent;
    }
    return '';
  };

  const onDrop = useCallback(
    async ([file]) => {
      setSelectedFile(file);
      if (onSelectFile) {
        onSelectFile(file);
      }
      setError(null);
      setData(null);
      onGetInvalidRecord && onGetInvalidRecord([]);
      onBeginReadFile && onBeginReadFile();
      try {
        const readFile = await readFileAsText(file);
        if (readFile || readFile === CVSChar.emptyString) {
          const content = parseCSV(readFile);
          const { isValidFile, errorMsg } = checkMalformedFile(file, [
            ...content
          ]);
          if (isValidFile && !errorMsg) {
            setData(content);
          } else {
            throw new Error(errorMsg || FileError.marformedCSV);
          }
        }
      } catch (e) {
        setError(e.message || FileError.marformedCSV);
        setData(null);
      }
    },
    [readFile, onSelectFile, onBeginReadFile, onEndReadFile]
  );

  useEffect(() => {
    if (data || error) {
      onEndReadFile && onEndReadFile(error, data);
      setError(null);
    }
  }, [error, data, onEndReadFile]);

  return (
    <Container>
      <div>{children}</div>
      <ReactDropzone
        // accept=".csv"
        onDrop={onDrop}
        multiple={false}
        className="dropzone-container"
        disabled={loading}
      >
        <div className="d-center">
          {!selectedFile ? (
            <span> {descriptionHolder} </span>
          ) : (
            <div className="d-center direction-col">
              <div className="icon-35">
                <Image src={csvIcon} />
              </div>
              <div style={{ marginTop: 10 }}>{selectedFile.name}</div>
            </div>
          )}
        </div>
      </ReactDropzone>
    </Container>
  );
}

CsvFileInput.propTypes = {
  readFile: PropTypes.bool,
  headerText: PropTypes.string,
  onSelectFile: PropTypes.func,
  onBeginReadFile: PropTypes.func,
  onEndReadFile: PropTypes.func,
  descriptionHolder: PropTypes.string,
  loading: PropTypes.bool,
  maxSize: PropTypes.number
};

export default CsvFileInput;
