import React from 'react';
import _ from 'lodash';
import { FormattedNumber } from 'react-intl';
import { Table, Dropdown, Label } from 'semantic-ui-react';
import { utcMoment, dateTimeFormatted } from '../../utilities/dateUtils';
import { kindFormatted } from '../../utilities/productUtil';

const ListRow = actionHandler => guaranteePurchase => {
  const rowMenuOptions = [
    { key: 1, text: 'Edit', value: 'Edit' },
    { key: 2, text: 'Extend Guarantee', value: 'Extend Guarantee' },
    { key: 3, text: 'Cancel Guarantee', value: 'Cancel Guarantee' },
    { key: 4, text: 'Status History', value: 'Audit' },
    { key: 5, text: 'Vehicle Status Tracker', value: 'Vehicle Status Tracker' }
  ];

  const {
    id,
    kind,
    expireGuarantee,
    guaranteedAt,
    partner,
    guaranteeOffer: {
      guaranteeStatus,
      leftLot,
      daysSelected,
      milesSelected,
      guaranteePrice,
      paymentKind,
      externalId,
      product,
      vehicleInformation: { vin, make, model, odometerReading, year },
      auctionInformation: {
        locationInitials,
        saleNumber,
        runNumber,
        laneNumber,
        vehiclePurchasePrice,
        dealerNumber
      }
    }
  } = guaranteePurchase;

  const getProductName = product => {
    return product
      ? product.name
      : `${kindFormatted(kind)} (${daysSelected} D ${milesSelected} M)`;
  };

  const vehicle = `${year} ${make} ${model}`;
  const currencyProps = price => {
    return {
      currency: 'USD',
      style: 'currency',
      minimumFractionDigits: 0,
      value: price
    };
  };
  const auctionInfo = [
    locationInitials,
    saleNumber,
    laneNumber,
    runNumber
  ].join(' / ');

  const handleActionChange = (e, { name, value }) => {
    e.preventDefault();
    actionHandler(value, guaranteePurchase);
  };

  const labels = [];
  if (leftLot) {
    labels.push(
      <Label key="left lot" color="red" horizontal>
        Left Lot
      </Label>
    );
  }
  if (expireGuarantee) {
    labels.push(
      <Label key="Expired" color="red" horizontal>
        Expired
      </Label>
    );
  }

  return (
    <Table.Row key={id}>
      <Table.Cell>
        {vin} {labels}
      </Table.Cell>
      <Table.Cell>{externalId}</Table.Cell>
      <Table.Cell>{dateTimeFormatted(utcMoment(guaranteedAt))}</Table.Cell>
      <Table.Cell>{dealerNumber}</Table.Cell>
      <Table.Cell>{auctionInfo}</Table.Cell>
      <Table.Cell>{vehicle}</Table.Cell>
      <Table.Cell>{odometerReading}</Table.Cell>
      <Table.Cell>
        <FormattedNumber {...currencyProps(vehiclePurchasePrice)} />
      </Table.Cell>
      <Table.Cell>{guaranteeStatus}</Table.Cell>
      <Table.Cell>{getProductName(product)}</Table.Cell>
      <Table.Cell>
        <FormattedNumber {...currencyProps(guaranteePrice)} />
      </Table.Cell>
      <Table.Cell>{partner}</Table.Cell>
      <Table.Cell>{paymentKind}</Table.Cell>
      <Table.Cell>
        <Dropdown
          text="Actions"
          options={rowMenuOptions}
          icon="ellipsis horizontal"
          onChange={handleActionChange}
          inline
          item
          selectOnBlur={false}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default ListRow;
