export const readFileAsText = fileName => {
  if (!fileName) {
    throw new Error();
  }
  return new Promise(function(resolve) {
    const reader = new FileReader();
    reader.onload = e => {
      const contents = e.target.result;
      resolve(contents);
    };
    reader.onerror = e => {
      resolve(null);
    };
    reader.readAsText(fileName);
  });
};

export const isValidFileSize = (file, expectedSize) => {
  const maxSize = expectedSize * 1024 * 1024;
  return file && file.size <= maxSize;
};

export function getFileExtension(filename) {
  const parts = filename.split('.');
  return parts.length > 1 ? parts.pop() : '';
}
