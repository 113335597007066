module.exports = {
  SOP_OPTIONS: [
    {
      key: '1',
      text: 'Auction Edge',
      value: 'AUCTION_EDGE'
    },
    {
      key: '2',
      text: 'Auction Os',
      value: 'AUCTION_OS'
    },
    {
      key: '3',
      text: 'XCIRA',
      value: 'XCIRA'
    }
  ],
  UPLOAD_OPTIONS: [
    {
      key: 'options1',
      text: 'Process SOP Event',
      value: 'SopEventsUploader',
      label: 'SOP Event',
      desc: 'Use this option to upload an SOP file to APMS for processing.'
    },
    {
      key: 'options2',
      text: 'Add/Update Fee Codes',
      value: 'FeeCodeUploader',
      label: 'Fee Codes',
      desc: 'Use this option to import fee codes into APMS by using csv file'
    },
    {
      key: 'options3',
      text: 'Add/Update Ineligible Lanes',
      value: 'IneligibleLanesUploader',
      label: 'Ineligible Lanes',
      desc:
        'Use this option to import ineligible lanes into APMS using csv file'
    },
    {
      key: 'options4',
      text: 'Add/Update How Paid Codes',
      value: 'HowPaidCodesUploader',
      label: 'How Paid Codes',
      desc: 'Use this option to import how paid codes into APMS using csv file'
    },
    {
      key: 'options5',
      text: 'Add/Update Bad Seller File',
      value: 'BadSellerFileUploader',
      label: 'Bad Seller File',
      desc: 'Use this option to import bad seller into APMS using csv file'
    },
    {
      key: 'options6',
      text: 'Upload DS Select Seller List',
      value: 'DsSelectSellerUploader',
      label: 'DS Select Seller List',
      desc:
        'Use this option to upload Ds Select seller into APMS using csv file'
    }
  ]
};
