import React from 'react';
import { Grid, Label, Segment, Icon, Header } from 'semantic-ui-react';
import ReactJson from 'react-json-view';
import { isEmpty } from 'lodash';

function SearchResonse(props) {
  const {
    response: { responseBody, responseCode, requestUrl, requestMethod, success }
  } = props;
  const responseCodeValue = parseInt(responseCode, 10);
  const responseColor = responseCodeValue === 200 ? 'green' : 'red';
  const jsonResponse = responseBody ? JSON.parse(responseBody) : {};

  return (
    <div>
      {!isEmpty(responseCode) && (
        <Grid container>
          <Grid.Row>
            <Segment color={responseColor} style={{ width: '100%' }}>
              <Label color={responseColor} horizontal>
                {responseCode}
              </Label>

              <Label color={responseColor} horizontal>
                {requestMethod}
              </Label>
              {requestUrl}
            </Segment>
          </Grid.Row>
          <Grid.Row>
            <Segment color={responseColor} style={{ width: '100%' }}>
              <Label attached="top" color={responseColor}>
                Response Body
              </Label>
              <br />
              <div
                style={{
                  overflow: 'auto',
                  minHeight: '400px',
                  maxHeight: '600px'
                }}
              >
                {!success && responseCodeValue === 0 && (
                  <div>Something Went Wrong...</div>
                )}
                {success && (
                  <div>
                    <ReactJson
                      collapsed={false}
                      collapseStringsAfterLength={50}
                      groupArraysAfterLength={20}
                      enableClipboard={false}
                      displayObjectSize={false}
                      onEdit={false}
                      quotesOnKeys={false}
                      displayArrayKey={false}
                      name={false}
                      src={jsonResponse}
                    />
                  </div>
                )}
                {!success && responseCodeValue !== 0 && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: responseBody
                    }}
                  />
                )}
              </div>
            </Segment>
          </Grid.Row>
        </Grid>
      )}
      {isEmpty(responseCode) && (
        <Segment
          disabled
          textAlign="center"
          style={{ top: '0', right: '0', bottom: '0', left: '0' }}
        >
          <Header icon>
            <Icon name="info circle" />
            Preview HTTP Response
          </Header>
        </Segment>
      )}
    </div>
  );
}

export default SearchResonse;
