import React from 'react';
import { Table, Dropdown } from 'semantic-ui-react';
import moment from 'moment';

const ListRow = actionHandler => (dealer, index) => {
  let rowMenuOptions = [
    { key: 1, text: 'Edit', value: 'Edit' },
    { key: 2, text: 'Delete', value: 'Delete' },
    { key: 3, text: 'Bulk Import Dealer', value: 'Import' }
  ];

  const { groupId, groupName, description, createdAt, updatedAt } = dealer;

  const handleActionChange = (e, { name, value }) => {
    e.preventDefault();
    actionHandler(value, dealer);
  };

  return (
    <Table.Row key={index}>
      <Table.Cell>{groupId}</Table.Cell>
      <Table.Cell className="space-pre-wrap">{groupName}</Table.Cell>
      <Table.Cell>{description}</Table.Cell>
      <Table.Cell>{moment(createdAt).format('YYYY-MM-DD HH:mm:ss')}</Table.Cell>
      <Table.Cell>{moment(updatedAt).format('YYYY-MM-DD HH:mm:ss')}</Table.Cell>
      <Table.Cell>
        <Dropdown
          text="Actions"
          options={rowMenuOptions}
          icon="ellipsis horizontal"
          inline
          item
          selectOnBlur={false}
          onChange={handleActionChange}
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default ListRow;
