import React, { Component } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Form,
  Button,
  Modal,
  Icon,
  Message,
  Select,
  Checkbox
} from 'semantic-ui-react';
import { utcMoment, formatDateOnly } from '../../utilities/dateUtils';
import { isEmpty, compact } from 'lodash';
import Validator from 'validatorjs';
import subscriptionProductTypeQuery from '../../graphql/subscriptionProductTypeQuery';
import client from '../../apollo';
import MaskedInput from 'react-text-mask';

class SubscriptionNew extends Component {
  state = {
    dealerName: '',
    dealerNumber: '',
    productId: null,
    productName: '',
    subscriptionType: '',
    priceDs: null,
    pricePsiLpi: null,
    notifyEmail: '',
    startDate: utcMoment(moment(new Date()).add(1, 'days')),
    endDate: null,
    productTypes: [],
    errors: {},
    activeSubscription: false,
    dsPaidPsi: true
  };

  onSelectHandler = field => (event, data) => {
    event.preventDefault();

    const { key } = data.options.find(o => o.value === data.value);
    this.setState({ [field]: data.value, productId: key, pricePsiLpi: null });
  };

  onNumberChangeHandler = field => e => {
    const REGEX_NUMBER = /^[0-9\b]+$/i;

    if (REGEX_NUMBER.test(e.target.value) || isEmpty(e.target.value)) {
      this.setState({ [field]: e.target.value, errors: { [field]: '' } });
      return true;
    } else {
      this.setState({
        [field]: e.target.value,
        errors: { [field]: 'Invalid format number' }
      });
      return false;
    }
  };

  onEmailChangeHandler = field => event => {
    this.setState({ [field]: event.target.value });
  };

  onNewFormCloseHandler = () => {
    this.setState({
      activeSubscription: false
    });
  };

  validationRules = {
    priceDs: 'required|numeric',
    productName: 'required|string',
    pricePsiLpi: 'numeric',
    notifyEmail: 'required',
    startDate: 'required'
  };

  componentDidMount() {
    this.getProductTypesQuery();
  }

  getProductTypesQuery = () => {
    client
      .query({
        query: subscriptionProductTypeQuery
      })
      .then(({ data }) => {
        const { subscriptionProductType: productTypes } = data;
        this.setState({ productTypes: productTypes });
      })
      .catch(error => {
        console.log('Error: ', error);
      });
  };

  validateStartDateField = (field = 'startDate', value) => {
    let startDate = value;
    startDate = moment(new Date(startDate)).format('YYYY-MM-DD');
    let currentDate = moment(new Date()).format('YYYY-MM-DD');

    if (isEmpty(startDate) && field === 'startDate') {
      return 'This field is required';
    }

    if (
      moment(value).isValid() === false &&
      startDate === 'Invalid date' &&
      !isEmpty(value)
    ) {
      return 'Please input with a correct format value of YYYY-MM-DD';
    }

    if (
      !isEmpty(startDate) &&
      field === 'startDate' &&
      moment(new Date(startDate)) > moment(new Date()).add(30, 'days')
    ) {
      return 'Please enter date should be older than 30 days';
    }

    if (
      !isEmpty(startDate) &&
      field === 'startDate' &&
      startDate <= currentDate
    ) {
      return 'Please enter date in a future date (Tomorrow day)';
    }

    return '';
  };

  validateEndDateField = (field = 'endDate', value, startDate) => {
    let endDate = value;
    let startD = moment(new Date(startDate)).format('YYYY-MM-DD');

    endDate = moment(new Date(endDate)).format('YYYY-MM-DD');

    if (
      moment(value).isValid() === false &&
      endDate === 'Invalid date' &&
      !isEmpty(value)
    ) {
      return 'Please input with a correct format value of YYYY-MM-DD';
    }

    if (isEmpty(endDate) && field === 'endDate') {
      return '';
    }

    if (
      !isEmpty(startD) &&
      !isEmpty(endDate) &&
      field === 'endDate' &&
      moment(new Date(endDate)) <= moment(new Date(startD))
    ) {
      return 'End Date cannot be less equal than Start Date';
    }

    return '';
  };

  showMsgValidationDate = (field, value) => {
    let msg = '';

    if (field === 'startDate') {
      msg = this.validateStartDateField(field, value);
    }

    let { startDate } = this.state;
    if (field === 'endDate') {
      msg = this.validateEndDateField(field, value, startDate);
    }

    return msg;
  };

  onDateChangeHandler = field => date => {
    this.setState({
      [field]:
        !isEmpty(date) &&
        moment(date).isValid() &&
        moment(new Date(date._d)).format('YYYY-MM-DD'),
      errors: {
        [field]: ''
      }
    });
  };

  onDateFocusHandler = field => event => {
    const val = event.target.value;

    let { endDate } = this.state;

    if (
      field === 'startDate' &&
      moment(new Date(val)) >= moment(new Date(endDate))
    ) {
      this.setState({
        startDate: val,
        endDate: '',
        errors: {
          startDate: ''
        }
      });
      return;
    }

    const msg = this.showMsgValidationDate(field, val);

    this.setState({
      [field]: val,
      errors: {
        [field]: msg
      }
    });
  };

  onCheckboxHandler = field => (event, data) => {
    this.setState({ [field]: data.checked });
  };

  isNonBundleProduct = prdName => {
    const productName = prdName.toLowerCase();
    return (
      productName === 'dealshield 21-day' ||
      productName === 'dealshield 30-day' ||
      productName === 'dealshield select 21-day' ||
      productName === 'dealshield select 30-day'
    );
  };

  render() {
    let {
      isModalOpen,
      onCloseHandler,
      onSubmitHandler,
      submitting,
      subscription,
      error,
      onOpenConfirmationPopup,
      onCloseConfirmationPopup,
      onContinueCreateSubscription,
      isConfirmationPopup,
      dealerInfo
    } = this.props;

    const bundlePricePsiLpi = prodName => {
      return this.isNonBundleProduct(prodName) ? '' : pricePsiLpi;
    };

    const submitForm = () => {
      let {
        productId,
        productName,
        subscriptionType,
        priceDs,
        pricePsiLpi,
        notifyEmail,
        startDate,
        endDate,
        dsPaidPsi
      } = this.state;

      const REGEX_DATE = /[0-9]{4}-(0?[1-9]|1[012])-(0[0-9]|[12][0-9]|3[01])/i;

      if (
        moment(startDate._d) === 'Invalid Date' &&
        REGEX_DATE.test(startDate) === false &&
        !isEmpty(startDate)
      ) {
        this.setState({
          errors: {
            startDate: 'Please input with a correct format value of YYYY-MM-DD'
          }
        });
        return false;
      }

      if (
        moment(endDate) === 'Invalid Date' &&
        REGEX_DATE.test(endDate) === false &&
        !isEmpty(endDate)
      ) {
        this.setState({
          errors: {
            endDate: 'Please input with a correct format value of YYYY-MM-DD'
          }
        });
        return false;
      }

      startDate = isEmpty(startDate)
        ? ''
        : moment(new Date(startDate)).isValid()
        ? moment(new Date(startDate)).format('YYYY-MM-DD')
        : 'Invalid Date';
      endDate = isEmpty(endDate)
        ? ''
        : moment(new Date(endDate)).isValid()
        ? moment(new Date(endDate)).format('YYYY-MM-DD')
        : 'Invalid Date';

      const REGEX_MULTIPLE_EMAILS = /^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})((,| ,|, | , |;| ;|; | ; |){1}[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})*$/i;
      const REGEX_EMAIL_SEPARATES = /(?:,)|(?:;)|(\s)/g;
      const formatNotifyEmail = compact(
        notifyEmail.split(REGEX_EMAIL_SEPARATES)
      )
        .map(email => email.replaceAll(' ', ''))
        .filter(Boolean)
        .join(',');

      const validation = new Validator(this.state, this.validationRules, {
        'required.productName': 'This field is required',
        'required.priceDs': 'This field is required',
        'required.notifyEmail': 'This field is required',
        'required.startDate': 'This field is required'
      });

      if (moment(new Date(startDate)) > moment(new Date()).add(30, 'days')) {
        this.setState({
          errors: {
            startDate: 'Please enter date should be older than 30 days'
          }
        });
        return false;
      }

      if (
        !isEmpty(startDate) &&
        moment(new Date(startDate)) <= moment(new Date())
      ) {
        this.setState({
          errors: {
            startDate: 'Please enter date in a future date (Tomorrow day)'
          }
        });
        return false;
      }

      if (
        !isEmpty(startDate) &&
        !isEmpty(endDate) &&
        moment(new Date(endDate)) <= moment(new Date(startDate))
      ) {
        this.setState({
          endDate: moment(new Date(endDate)).format('YYYY-MM-DD'),
          errors: {
            endDate: 'End Date cannot be less equal than Start Date'
          }
        });
        return false;
      }

      if (parseFloat(priceDs) !== 0 && parseFloat(priceDs) < 100) {
        this.setState({
          errors: {
            priceDs:
              'Please input with a correct Dealshield price (0 or from 100 to 999)'
          }
        });
        return false;
      }

      if (
        !isEmpty(`${pricePsiLpi}`) &&
        parseFloat(pricePsiLpi) !== 0 &&
        parseFloat(pricePsiLpi) < 100
      ) {
        this.setState({
          errors: {
            pricePsiLpi:
              'Please input with a correct PSI/LPI price (0 or from 100 to 999)'
          }
        });
        return false;
      }

      if (validation.fails()) {
        this.setState({ ...validation.errors });
        return false;
      }

      if (!REGEX_MULTIPLE_EMAILS.test(notifyEmail)) {
        this.setState({
          errors: {
            notifyEmail:
              'Invalid email format. If using multi email e.g. user1@gmail.com,user2@gmail.com,...'
          }
        });
        return false;
      }

      pricePsiLpi = this.isNonBundleProduct(productName) ? 0 : pricePsiLpi;

      onSubmitHandler({
        dealerName: subscription[0].dealerName,
        dealerNumber: parseInt(subscription[0].dealerNumber, 10),
        productId: parseInt(productId, 10),
        productName: productName,
        subscriptionType: subscriptionType || 'BUYER',
        priceDs: parseFloat(priceDs),
        pricePsiLpi: parseFloat(pricePsiLpi),
        notifyEmail: formatNotifyEmail || '',
        startDate: startDate,
        endDate: endDate,
        active: true,
        sameDayPriceUpdate: false,
        subscription: subscription,
        dsPaidPsi: dsPaidPsi
      });
    };

    let {
      productId,
      productName,
      priceDs,
      pricePsiLpi,
      notifyEmail,
      startDate,
      endDate,
      dsPaidPsi,
      productTypes,
      errors
    } = this.state;

    let getDealerInfo = subscription => {
      const dealerInfor =
        !isEmpty(subscription) &&
        !isEmpty(subscription[0].dealerNumber) &&
        !isEmpty(subscription[0].dealerName)
          ? `${subscription[0].dealerNumber} - ${subscription[0].dealerName}`
          : !isEmpty(subscription) &&
            !isEmpty(subscription[0].dealerNumber) &&
            isEmpty(subscription[0].dealerName)
          ? `${subscription[0].dealerNumber}`
          : '';
      return dealerInfor;
    };

    return (
      <div>
        {isModalOpen && (
          <Modal
            open={isModalOpen}
            closeIcon
            onClose={onCloseHandler}
            closeOnEscape={false}
            closeOnRootNodeClick={false}
          >
            <Modal.Header style={headerStyle}>
              <span>Create new subscription</span>
              <span>{dealerInfo}</span>
            </Modal.Header>
            <Modal.Content image scrolling>
              <Modal.Description>
                {error && (
                  <Message negative>
                    <p>{error}</p>
                  </Message>
                )}
                <Form>
                  <Form.Group widths="equal">
                    <Form.Field required error={!isEmpty(errors.productName)}>
                      <label>DealShield Product Selection</label>
                      <Select
                        placeholder="Select DealShield Product"
                        options={productTypes}
                        closeOnBlur
                        value={productName}
                        key={productId}
                        onChange={this.onSelectHandler('productName')}
                        style={{ width: '50%' }}
                      />
                      {errors.productName && (
                        <div style={{ color: 'red' }}>{errors.productName}</div>
                      )}
                    </Form.Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Field required error={!isEmpty(errors.priceDs)}>
                      <label>DealShield Price</label>
                      <input
                        name="priceDs"
                        value={priceDs}
                        onChange={this.onNumberChangeHandler('priceDs').bind(
                          this
                        )}
                        placeholder="N/A"
                        type="text"
                        pattern="\d*"
                        maxlength="3"
                      />
                      {errors.priceDs && (
                        <div style={{ color: 'red' }}>{errors.priceDs}</div>
                      )}
                    </Form.Field>

                    <Form.Field error={!isEmpty(errors.pricePsiLpi)}>
                      <label>DealShield PSI / LPI Price</label>
                      <input
                        name="pricePsiLpi"
                        value={bundlePricePsiLpi(productName)}
                        onChange={this.onNumberChangeHandler(
                          'pricePsiLpi'
                        ).bind(this)}
                        placeholder="N/A"
                        type="text"
                        pattern="\d*"
                        maxlength="3"
                        disabled={this.isNonBundleProduct(productName)}
                      />
                      {errors.pricePsiLpi && (
                        <div style={{ color: 'red' }}>{errors.pricePsiLpi}</div>
                      )}
                    </Form.Field>
                  </Form.Group>

                  <Form.Group>
                    <Form.Field
                      style={{ width: '50%' }}
                      required
                      error={!isEmpty(errors.notifyEmail)}
                    >
                      <label>POC Email Address</label>
                      <input
                        name="notifyEmail"
                        placeholder="Please input email"
                        value={notifyEmail}
                        onChange={this.onEmailChangeHandler('notifyEmail').bind(
                          this
                        )}
                      />
                      {errors.notifyEmail && (
                        <div style={{ color: 'red' }}>{errors.notifyEmail}</div>
                      )}
                    </Form.Field>
                  </Form.Group>

                  <Form.Group>
                    <Form.Field required error={!isEmpty(errors.startDate)}>
                      <label>Subscription Start Date</label>
                      <DatePicker
                        style={{ padding: '0', width: '100%' }}
                        selected={
                          !isEmpty(startDate) && moment(startDate).isValid()
                            ? moment(startDate)
                            : moment(new Date()).add(1, 'days')
                        }
                        minDate={moment(new Date(), 'YYYY-MM-DD').add(
                          1,
                          'days'
                        )}
                        maxDate={moment(new Date(), 'YYYY-MM-DD').add(
                          29,
                          'days'
                        )}
                        onChange={this.onDateChangeHandler('startDate')}
                        onBlur={this.onDateFocusHandler('startDate')}
                        dateFormat="YYYY-MM-DD"
                        maxlength="10"
                        customInput={
                          <MaskedInput
                            mask={[
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              '/',
                              /\d/,
                              /\d/,
                              '/',
                              /\d/,
                              /\d/
                            ]}
                            keepCharPositions={true}
                            guide={true}
                          />
                        }
                      />
                      {errors.startDate && (
                        <div style={{ color: 'red' }}>{errors.startDate}</div>
                      )}
                    </Form.Field>

                    <Form.Field error={!isEmpty(errors.endDate)}>
                      <label>Subscription End Date</label>
                      <DatePicker
                        style={{ padding: '0', width: '100%' }}
                        selected={
                          !isEmpty(endDate) && moment(endDate).isValid()
                            ? moment(endDate)
                            : null
                        }
                        minDate={moment(
                          new Date(startDate),
                          'YYYY-MM-DD ZZ'
                        ).add(1, 'days')}
                        maxDate={moment(
                          new Date(startDate),
                          'YYYY-MM-DD ZZ'
                        ).add(29, 'days')}
                        onChange={this.onDateChangeHandler('endDate')}
                        onBlur={this.onDateFocusHandler('endDate')}
                        dateFormat="YYYY-MM-DD"
                        maxlength="10"
                        customInput={
                          <MaskedInput
                            mask={[
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              '/',
                              /\d/,
                              /\d/,
                              '/',
                              /\d/,
                              /\d/
                            ]}
                            keepCharPositions={true}
                            guide={true}
                          />
                        }
                      />
                      {errors.endDate && (
                        <div style={{ color: 'red' }}>{errors.endDate}</div>
                      )}
                    </Form.Field>
                    <Form.Field>
                      <label>DS Paid PSI</label>
                      <Checkbox
                        checked={dsPaidPsi}
                        onChange={this.onCheckboxHandler('dsPaidPsi')}
                      />
                    </Form.Field>
                  </Form.Group>
                </Form>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              {!submitting && (
                <Button color="green" onClick={submitForm} inverted>
                  <Icon name="checkmark" /> Create
                </Button>
              )}
              <Button onClick={onCloseHandler}>Cancel</Button>
            </Modal.Actions>
          </Modal>
        )}
        <div>
          {onOpenConfirmationPopup && isConfirmationPopup && (
            <Modal
              open={onOpenConfirmationPopup}
              closeIcon
              onClose={onCloseConfirmationPopup}
              closeOnEscape={false}
              closeOnRootNodeClick={false}
            >
              <Modal.Header style={headerStyle}>
                <span>Create new subscription</span>
                <span>{dealerInfo}</span>
              </Modal.Header>
              <Modal.Content image scrolling>
                <Modal.Description>
                  <p>
                    {' '}
                    There is an active subscription for this dealer. If a new
                    subscription is created, the existing subscription will
                    automatically be expired today. The new subscription will be
                    valid from tomorrow.
                    <br />
                    Do you want to process?
                  </p>
                </Modal.Description>
              </Modal.Content>
              <Modal.Actions>
                {
                  <Button color="green" onClick={onContinueCreateSubscription}>
                    <Icon name="checkmark" />
                    Continue.
                  </Button>
                }
                <Button onClick={onCloseConfirmationPopup}>Cancel</Button>
              </Modal.Actions>
            </Modal>
          )}
        </div>
      </div>
    );
  }
}

const headerStyle = {
  display: 'grid',
  justifyContent: 'space-between',
  gridTemplateColumns: 'auto auto'
};

export default SubscriptionNew;
