import { gql } from 'react-apollo';

const checkGroupDealerMutation = gql`
  mutation checkGroupDealer($groupId: Int!, $dealerNumber: [Int!]!) {
    checkGroupDealer(
      input: { groupId: $groupId, dealerNumber: $dealerNumber }
    ) {
      success
      code
      message
      data {
        groupId
        dealerNumber
        status
        message
      }
    }
  }
`;

export default checkGroupDealerMutation;
