import { gql } from 'react-apollo';

const guaranteePurchasesQuery = gql`
  query guaranteePurchasesQuery(
    $vin: String
    $size: Int
    $offset: Int
    $dealerNumbers: [String]
  ) {
    guaranteePurchases(
      vin: $vin
      offset: $offset
      size: $size
      dealerNumbers: $dealerNumbers
    ) {
      id
      kind
      extensionDays
      additionalDays
      expireGuarantee
      extensionReason
      partner
      purchasingUserId
      guaranteedAt
      expiresAt
      channel
      orderNumber
      isReturned
      guaranteeOffer {
        id
        milesSelected
        daysSelected
        emails
        guaranteeStatus
        guaranteePrice
        transportReimbursement
        leftLot
        paymentKind
        externalId
        product {
          name
          productCode
          daysDs
          milesDs
          daysPsi
          isActive
        }
        vehicleInformation {
          id
          vin
          vehicleCode
          make
          model
          odometerReading
          year
        }
        auctionInformation {
          id
          locationInitials
          dealerNumber
          laneNumber
          auctionAccessNumber
          saleNumber
          buyFee
          runNumber
          vehiclePurchasePrice
        }
      }
    }
  }
`;

export default guaranteePurchasesQuery;
