import { gql } from 'react-apollo';

const importGroupDealerMutation = gql`
  mutation importGroupDealer($groupId: Int!, $dealerNumber: [Int!]!) {
    importGroupDealer(
      input: { groupId: $groupId, dealerNumber: $dealerNumber }
    ) {
      success
      code
      message
      data {
        groupId
        dealerNumber
        status
        message
        isImported
      }
    }
  }
`;

export default importGroupDealerMutation;
