import React from 'react';
import { Table, Button, Modal, Icon } from 'semantic-ui-react';
import ResponseModal from './ResponseModal';
import { isEmpty } from 'lodash';

const ListRow = () => allFiles => {
  const processResponse = record => {
    return (
      <div>
        <Modal
          trigger={<a href="javascript:void(0);">More</a>}
          header="Response Body"
          content={record.response}
          actions={[{ key: 'done', content: 'Done', positive: true }]}
        />
      </div>
    );
  };

  const {
    id,
    fileUrl,
    responseCode,
    response,
    jobProcessed,
    source,
    inputFile,
    status,
    fileName,
    fileType
  } = allFiles;

  const replace = value => {
    return value === null || value === 'null' ? null : value.replace('_', '  ');
  };

  const getResponseBody = response => {
    if (response) {
      return response.substring(0, MaxResponseLength);
    } else {
      return '';
    }
  };

  const MaxResponseLength = 30;
  const showModal = !isEmpty(response) && response.length > MaxResponseLength;
  const unwarningStatuses = ['processing', 'cancelled'];
  const showWarning = !(
    responseCode === '200' ||
    (responseCode === null && unwarningStatuses.includes(status))
  );
  const responseShort = getResponseBody(response);

  return (
    <Table.Row negative={showWarning} key={id} textAlign="center">
      <Table.Cell singleLine className="table-field">
        <a href={inputFile}>
          <p>{fileName}</p>
        </a>
      </Table.Cell>
      <Table.Cell className="table-field">
        <p>{fileType}</p>
      </Table.Cell>
      <Table.Cell className="table-field">
        <p>{replace(source)}</p>
      </Table.Cell>
      <Table.Cell className="table-field">
        {replace(status.toUpperCase())}
      </Table.Cell>
      <Table.Cell className="table-field">
        {jobProcessed === true && (
          <Icon color="green" name="checkmark" size="large" />
        )}
        {jobProcessed === false && (
          <Icon color="red" name="close" size="large" />
        )}
      </Table.Cell>
      <Table.Cell className="table-field">
        <p>{responseCode}</p>
      </Table.Cell>
      <Table.Cell className="table-field">
        {responseShort}
        {isEmpty(response) && 'N/A'}
        {showModal && processResponse(allFiles)}
      </Table.Cell>
    </Table.Row>
  );
};

export default ListRow;
